@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {

    /**
 * Remove the default box-shadow for invalid elements to prevent
 * inputs in Livewire components showing with a
 * red border by default in Firefox.
 *
 * See: https://github.com/laravel-frontend-presets/tall/issues/7
 */
    input:invalid, textarea:invalid, select:invalid {
        box-shadow: none;
    }

@import 'utilities/stars.css';
@import "media-library-pro-styles";
@import 'plugins/nprogress.css';
@import '../../node_modules/pikaday/css/pikaday.css';

}





